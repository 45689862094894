<template>
  <div class="mx-auto flex flex-col justify-between pb-8">
    <div v-if="subscriptionLoaded">
      <HeaderV2></HeaderV2>

      <div :class="wrapperStyling">
        <CurrentStep></CurrentStep>
      </div>
    </div>

    <LoadingView v-else :message="'Hämtar kundinfo'"></LoadingView>

    <div class="md:max-w-2x md:mx-auto md:mt-12" v-if="isTest">
      <i class="fas fa-user-secret"></i>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HeaderV2 from './components/Header';
import LoadingView from './components/LoadingView';
import CurrentStep from './components/CurrentStep';
import { gtm } from './utils/gtm';

export default {
  name: 'save-flow-v2',
  components: {
    CurrentStep,
    LoadingView,
    HeaderV2,
  },
  mounted() {
    this.fetchActivePaymentMethod();
    this.fetchIsTest();
    this.fetchTestZip();
    this.fetchOneClickBuy();
    this.fetchSteps();
    this.fetchSubscription();
    this.fetchSteps();
    this.fetchMarket();
    this.fetchPrimaryColor();
    this.fetchSecondaryColor();
    this.fetchLogoUrl();
    this.fetchEmail();
    this.fetchCancelUrl();
    this.fetchBaseUrl();
    this.fetchQueryParams();
    this.updateStepsFromQueryParam();
    gtm.pushNewSection(this.currentStepData);
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      'fetchTestZip',
      'fetchIsTest',
      'fetchOneClickBuy',
      'fetchSteps',
      'fetchSubscription',
      'fetchActivePaymentMethod',
      'fetchMarket',
      'fetchPrimaryColor',
      'fetchSecondaryColor',
      'fetchLogoUrl',
      'fetchEmail',
      'fetchCancelUrl',
      'fetchQueryParams',
      'updateStepsFromQueryParam',
      'fetchBaseUrl',
    ])
  },
  computed: {
    ...mapGetters(['subscriptionLoaded', 'currentStepData', 'isTest', 'currentStep']),
    wrapperStyling() {
      return 'md:max-w-600 md:mx-auto mt-6 md:mt-10';
    }
  }
};
</script>

<style lang="scss">
</style>
