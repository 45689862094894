<template>
  <transition name="modal" v-if="showModal">
    <div class="modal-mask" @click="maskClose">
      <div class="modal-wrapper">
        <div class="modal-container py-5 px-5">
          <div class="modal-header relative">
            <slot name="header"></slot>
            <i class="fal fa-times absolute right-0 top-0 modal-close text-bp-darkgray fa-lg fas fa-times cursor-pointer" @click="close(modalName)"></i>
          </div>

          <div ref="body" class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Button from './Button';

export default {
  components: { Button },
  data() {
    return {};
  },
  props: {
    modalName: {
      type: String,
      default: ''
    },
    closeLabel: {
      type: String,
      default: 'Ok'
    }
  },
  computed: {
    ...mapState('modalModule', {
      showModal(state) {
        if (this.modalName in state.modals) {
          return state.modals[this.modalName];
        }
        return false;
      }
    })
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.addLinks();
        }, 200);
      }
    }
  },

  methods: {
    addLinks() {
      if (!this.$refs.body) {
        return;
      }
      const links = this.$refs.body.getElementsByTagName('a');
      for (var i = 0; i < links.length; i++) {
        links[i].setAttribute('target', '_blank');
      }
    },
    maskClose(e) {
      if (e.target.className === 'modal-wrapper') {
        this.close(this.modalName);
      }
    },
    ...mapMutations({
      close: 'modalModule/close',
      open: 'modalModule/open'
    })
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-height: 80vh;
  overflow-y: scroll;
  max-width: 600px;
  width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
