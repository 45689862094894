<template>
  <div>
    <a
      data-widget-placement="Moreshop cross-sell product" v-bind:data-widget-title="product.name"
      v-bind:href="product.link + '?' + moreshopQueryParam"
      class="mb-6 block js-widget-click"
      target="_blank"
    >
      <div class="mb-2 h-48 flex items-center">
        <img v-bind:src="product.image_url" class="moreshop-spot__image"/>
      </div>

      <p class="font-bold mb-4 text-center h-10">{{ product.name }}</p>

      <div class="flex justify-center">
        <button class="btn btn--icon mt-3">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </a>
  </div>
</template>

<script>
import { translate } from '../store/helpers';

export default {
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    moreshopQueryParam: {
      type: String,
      default: '',
    }
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    }
  }
};
</script>

<style scoped></style>
