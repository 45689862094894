<template>
  <Button :buttonText="buttonText" :styling="styling" v-on:click="previous"></Button>
</template>

<script>
import {mapActions} from 'vuex';
import Button from './Button.vue';
import { scrollToTop } from '../store/helpers';

export default {
  components: {
    Button,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    styling: {
      type: String,
      default: 'btn btn--primary-cta',
    },
  },
  methods: {
    previous() {
      this.pushInteraction({
        event: 'interaction click',
        eventParams: { clickedText: this.buttonText },
        engagement: {
          type: 'click',
          component: {
            name: this.styling,
            type: this.styling,
          },
        },
      });

      scrollToTop();
      setTimeout(() => {
        this.previousStep();
      }, 400);
    },
    ...mapActions([
      'previousStep',
      'pushInteraction'
    ])
  },
};
</script>

<style scoped>

</style>
