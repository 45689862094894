
export async function sha256(message) {
  try {
    const msgBuffer = new TextEncoder().encode(message);

    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  } catch(e) {
    return message;
  }

}
