import $ from 'jquery';
import {getQueryParameter} from "@/apps/saveflow/store/helpers";
import Cookies from 'js-cookie';

export default {
  init() {
    this.addEvents();
    setTimeout(() => {
      const params = getQueryParameter();
      if(params && params.active === 'forgot-subscription') {
        this.eventHandlers.fetchSubscriptionModalForQueryParam();
      }
    }, 500)
  },

  addEvents() {
    $(document).on('click', '.js-ajax-modal', this.eventHandlers.fetchModal);
    $(document).on('click', '.js--maybe-ajax-modal', this.eventHandlers.maybeFetchModal.bind(this));
    $(document).on('click', '#ajax-modal .modal__overlay, #ajax-modal .modal-close', this.eventHandlers.closeModalTimes);
  },

  eventHandlers: {
    closeModalTimes() {
      $('#ajax-modal').addClass('hidden');
      $('#ajax-modal .modal__content').empty();
    },
    maybeFetchModal(e) {
      const cookieName = $(e.target).data('modal-cookie');

      if (typeof Cookies.get(cookieName) !== 'undefined') {
        return true;
      }

      this.eventHandlers.fetchModal(e);
    },
    fetchModal(e) {
      let $wrapper = $('#ajax-modal');
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      $wrapper.find('.modal__content').empty().addClass('open').html('<div class="flex w-full py-8 justify-center"><i class="fal fa-spin fa-2x fa-spinner z-10"></i></div>');

      $wrapper.removeClass('hidden').addClass($(e.target).data('modal-class'));
      const modal = $(e.target).data('modal');

      $.get(window.ajax_url + '?action=fetch_modal&modal=' + modal).then(res => {
        if (res && res.html) {
          $wrapper.find('.modal__content').html(res.html);
        }
      });
    },
    fetchSubscriptionModalForQueryParam() {
      let $wrapper = $('#ajax-modal');
      $wrapper.find('.modal__content').empty().addClass('open').html('<div class="flex w-full py-8 justify-center"><i class="fal fa-spin fa-2x fa-spinner z-10"></i></div>');

      $wrapper.removeClass('hidden').addClass($(this).data('modal-class'));

      $.get(window.ajax_url + '?action=fetch_modal&modal=forgot_subscription_number').then(res => {
        if (res && res.html) {
          $wrapper.find('.modal__content').html(res.html);
        }
      });
    }
  }
}
