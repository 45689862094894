<template>
  <div class="">
    <div class="sk-circle mb-4">
      <div class="sk-circle1 sk-child" :style="cssVariable"></div>
      <div class="sk-circle2 sk-child" :style="cssVariable"></div>
      <div class="sk-circle3 sk-child" :style="cssVariable"></div>
      <div class="sk-circle4 sk-child" :style="cssVariable"></div>
      <div class="sk-circle5 sk-child" :style="cssVariable"></div>
      <div class="sk-circle6 sk-child" :style="cssVariable"></div>
      <div class="sk-circle7 sk-child" :style="cssVariable"></div>
      <div class="sk-circle8 sk-child" :style="cssVariable"></div>
      <div class="sk-circle9 sk-child" :style="cssVariable"></div>
      <div class="sk-circle10 sk-child" :style="cssVariable"></div>
      <div class="sk-circle11 sk-child" :style="cssVariable"></div>
      <div class="sk-circle12 sk-child" :style="cssVariable"></div>
    </div>
    <p class="text-center font-medium" v-bind:style="{'color': primaryColor}">{{loadingMessage}}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '../store/helpers';

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      primaryColor: 'primaryColor',
      secondaryColor: 'secondaryColor',
    }),

    loadingMessage() {
      if (this.message.length > 0) {
        return this.message;
      }
      return translate('general', 'hold');
    },

    cssVariable() {
      return {
        '--primary-color': this.primaryColor,
      };
    },
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
  },
};
</script>

<style scoped lang="scss">
  .sk-child:before {
    background-color: var(--primary-color);
  }
</style>
