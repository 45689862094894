import { gtm } from '../../utils/gtm';

const state = {
  index: 0,
  extension: {},
  selectedCampaign: {},
  extensionLoaded: false,
  selectedOffer: {},
  steps: ['offers', 'checkout']
};

const getters = {
  currentStep(state) {
    return state.steps[state.index];
  },
  selectedCampaign(state) {
    return state.steps[state.index];
  },
  extension(state) {
    return state.extension;
  },
  getIndex(state) {
    return state.index;
  }
};

const actions = {
  init() {
    gtm.pushViewItem(this.getters.extension.offers.map((offer) => {
      return offer.newDataLayer;
    }));
  },
  prev({ commit }) {
    commit('prev');
    /*if (getters.currentStep === 'intro') {
      gtm.removeAddedProductFromCart();
    }*/
    const pageName = state.index === 0 ? 'landingpage' : state.steps[state.index];
    gtm.pushNewSection({
      dataLayer: {
        name: pageName.charAt(0).toUpperCase() + pageName.slice(1),
        pageName: `${
          this.getters.currentStepData.dataLayer.sectionId
        }.${state.index + 1} Extension|${pageName}`
      }
    });
  },
  next({ commit }) {
    commit('next');
    const pageName = state.index === 0 ? 'landingpage' : state.steps[state.index];
    gtm.pushNewSection({
      dataLayer: {
        name: pageName.charAt(0).toUpperCase() + pageName.slice(1),
        pageName: `${
          this.getters.currentStepData.dataLayer.sectionId
        }.${state.index + 2} Extension|${pageName}`
      }
    });
    gtm.initCheckout(state.selectedOffer.dataLayer);
    gtm.pushBeginCheckout(this.getters.extension.offers.map(offer => offer.newDataLayer));
  },
  setSelectedOffer({ commit }, offer) {
    commit('setSelectedOffer', offer);

    if (parseInt(offer.id, 10) !== -1) {
      gtm.selectOffer(state.selectedOffer.dataLayer);
    }
  }
};

const mutations = {
  setExtension(state, extension) {
    state.extension = extension;
    state.selectedCampaign = extension;
    state.selectedOffer = null;
    state.extensionLoaded = true;
  },
  setStep(state, step) {
    state.index = step;
  },
  setSelectedOffer(state, offer) {
    state.selectedOffer = offer;
  },
  prev(state) {
    state.index = state.index - 1 < 0 ? 0 : state.index - 1;
  },
  next(state) {
    state.index++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
