import $ from 'jquery';
import Pikaday from 'pikaday';
export default {
  init() {
    this.modifyDOM()
  },

  modifyDOM() {
    new Pikaday({
      field: $('#birthdate')[0],
      format: 'YYYY-MM-DD'
    });
  },
}
