import $ from 'jquery';
import mountVueApps from "../components/mountVueApps";
import readMore from '../components/read-more';
import sidebarMenu from '../components/sidebar-menu';
import viewCount from '../components/view-count';
import notices from '../components/notices';
import modal from '../components/modal';
import feedbackForm from '../components/feedback-form';
import gtmTracker from '../components/gtm-tracker';
export default {

  components: [
    mountVueApps,
    readMore,
    sidebarMenu,
    viewCount,
    notices,
    feedbackForm,
    gtmTracker,
    modal
  ],

  init() {
    this.addEvents();
    this.modifyDOM();
    this.components.forEach(c => {
      c.init();
    });
  },

  addEvents() {
    $(document).on('click', '.print-btn',  this.eventHandlers.print)
  },

  modifyDOM() {
  },

  eventHandlers: {
    print() {
      $(document).trigger('print-page');
      window.print();
    }
  }
};
