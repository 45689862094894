<template>
  <Button :buttonText="buttonText" :styling="styling" v-on:click="next"></Button>
</template>

<script>
import {mapActions} from 'vuex';
import Button from './Button.vue';
import { scrollToTop } from '../store/helpers';

export default {
  components: {
    Button,
  },
  methods: {
    next() {
      scrollToTop();
      setTimeout(() => {
        this.nextStep();
      }, 400);
    },
    ...mapActions([
      'nextStep'
    ])
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    styling: {
      type: String,
      default: 'btn btn--link',
    },
  },
};
</script>

<style scoped>

</style>
