import Vue from 'vue';
import Vuex from 'vuex';

import extensionModule from './modules/extension';
import confirmationModule from './modules/confirmation';
import modalModule from './modules/modal';
import newsletterModule from './modules/newsletter';
import { gtm } from '../utils/gtm';

Vue.use(Vuex);

const state = {
  currentStepIndex: 0,
  steps: [],
  customer: {},
  subscription: {},
  subscriptionLoaded: false,
  customerLoaded: false,
  market: '',
  primaryColor: '',
  secondaryColor: '',
  logoUrl: '',
  logoUrlMobile: '',
  email: '',
  hashedEmail: '',
  cancelUrl: '',
  myPageUrl: '',
  headerTextOverride: null,
  activePaymentMethod: null,
  isTest: false,
  testZip: false,
  queryParams: {},
  baseUrl: '',
  oneClickBuy: false,
};

const filterSteps = steps =>
  steps.filter(step => {
    if (step.type === 'extension') {
      return step.object && step.object.id;
    }
    if (step.type === 'cross-sell') {
      return step.object && step.object.length;
    }

    return true;
  });

const actions = {
  fetchBaseUrl({ commit }) {
    const urlWithoutQueryParams = window.location.href.split(/[?#]/)[0];
    const baseUrl = urlWithoutQueryParams.split('step')[0];
    commit('setBaseUrl', baseUrl);
  },
  fetchQueryParams({ commit }) {
    const urlWithoutQueryParams = window.location.href.split(/[?#]/)[0];
    const urlParts = urlWithoutQueryParams.split('step');

    if (urlParts.length > 1) {
      const paths = urlParts[1].split('/').filter((part) => {
        return part.length > 0;
      });
      commit('setQueryParams', {step: paths[0] ?? 0, path: paths[1] ?? 0});
    } else {
      commit('setQueryParams', {step: 0, path: 0});
    }
  },
  fetchActivePaymentMethod({ commit }) {
    commit('setActivePaymentMethod', window.application.activePaymentMethod);
  },
  fetchIsTest({ commit }) {
    commit('setIsTest', window.application.isTest === '1');
  },
  fetchTestZip({ commit }) {
    commit('setTestZip', window.application.testZip);
  },
  fetchOneClickBuy({ commit }) {
    commit('setOneClickBuy', window.application.saveFlow?.oneClickBuy);
  },
  fetchSubscription({ commit }) {
    commit('setSubscription', window.application.subscription);
  },
  fetchSteps({ commit }) {
    commit('setSteps', filterSteps(window.application.saveFlow.steps));
  },
  fetchMarket({ commit }) {
    commit('setMarket', window.application.market);
  },
  fetchExtension({ commit, getters }) {
    commit('extensionModule/setExtension', getters.extension);
  },
  fetchPrimaryColor({ commit }) {
    commit('setPrimaryColor', window.application.saveFlow.primaryColor);
  },
  fetchSecondaryColor({ commit }) {
    commit('setSecondaryColor', window.application.saveFlow.secondaryColor);
  },
  fetchLogoUrl({ commit }) {
    commit('setLogoUrl', window.application.saveFlow.logoUrl);
    commit('setMobileLogoUrl', window.application.saveFlow?.logoUrlMobile);
  },
  fetchEmail({ commit }) {
    commit('setEmail', window.application.saveFlow.email);
  },
  fetchHashedEmail({ commit }) {
    commit('setHashedEmail', window.application.saveFlow.hashedEmail);
  },
  fetchCancelUrl({ commit }) {
    commit('setCancelUrl', window.application.urls.cancel);
  },
  fetchMyPagesUrl({ commit }) {
    commit('setMyPagesUrl', window.application.urls.myPages);
  },
  nextStep({ commit, dispatch }) {
    commit('nextStep');
    dispatch('updateUrl');
    commit('pushNewSection');
  },
  previousStep({ commit, dispatch }) {
    commit('previousStep');
    dispatch('updateUrl');
    commit('pushNewSection');
  },
  updateStepsFromQueryParam({commit}) {
    if (state.queryParams.step > 0) {
      commit('setCurrentStepIndex', state.queryParams.step);
    }
  },
  updateUrl({commit}, path = null) {
    commit('setQueryParams', {step: state.currentStepIndex, path: path ?? 0});
    const newUrl = state.baseUrl + 'step/' + state.queryParams.step + '/' + state.queryParams.path + '/';
    console.log(window.location.href);
    window.history.replaceState({path: newUrl, referrer: window.location.href}, '', newUrl)
  },
  initCheckout(_, offerData) {
    gtm.initCheckout(offerData);
  },
  selectOffer(_, offerData) {
    gtm.selectOffer(offerData);
  },
  selectPaymentMethod(_, offerData, paymentOption) {
    gtm.selectPaymentOption(offerData, paymentOption);
  },
  clickPurchaseButton(_, offerData) {
    gtm.clickPurchaseButton(offerData);
  },
  removeFromCart(_, offerData) {
    gtm.removeFromCart(offerData);
  },
  setHeaderText({ commit }, text) {
    commit('setHeaderText', text);
  },
  pushInteraction(_, data) {
    gtm.pushInteraction(data);
  },
};

const mutations = {
  setBaseUrl(state, url) {
    this.state.baseUrl = url;
  },
  setQueryParams(state, params) {
    this.state.queryParams.step = parseInt(params.step, 10);
    this.state.queryParams.path = parseInt(params.path, 10);
  },
  setIsTest(state, isTest) {
    state.isTest = isTest;
  },
  setTestZip(state, testZip) {
    state.testZip = testZip;
  },
  setOneClickBuy(state, oneClickBuy) {
    state.oneClickBuy = oneClickBuy === true;
  },
  setMarket(state, market) {
    state.market = market;
  },
  setCurrentStep(state, step) {
    state.currentStep = step;
  },
  setCurrentStepIndex(state, currentStepIndex) {
    state.currentStepIndex = currentStepIndex;
  },
  setSteps(state, steps) {
    state.steps = steps;
  },
  setCustomer(state, customer) {
    state.customer = customer;
    state.customerLoaded = true;
  },
  setSubscription(state, subscription) {
    state.subscription = subscription;
    state.subscriptionLoaded = true;
  },
  setActivePaymentMethod(state, activePaymentMethod) {
    state.activePaymentMethod = activePaymentMethod;
  },
  setPrimaryColor(state, primaryColor) {
    state.primaryColor = primaryColor;
  },
  setSecondaryColor(state, secondaryColor) {
    state.secondaryColor = secondaryColor;
  },
  setHeaderText(state, headerText) {
    state.headerTextOverride = headerText;
  },
  setHeaderImage(state, headerImage) {
    state.headerImage = headerImage;
  },
  setHeaderImageMobile(state, headerImageMobile) {
    state.headerImageMobile = headerImageMobile;
  },
  setSelectedOffer(state, selectedOffer) {
    state.selectedOffer = selectedOffer;
  },
  setSelectedCampaign(state, selectedCampaign) {
    state.selectedCampaign = selectedCampaign;
  },
  setLogoUrl(state, logoUrl) {
    state.logoUrl = logoUrl;
  },
  setMobileLogoUrl(state,logoUrlMobile) {
    state.logoUrlMobile = logoUrlMobile ? logoUrlMobile : state.logoUrl;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setHashedEmail(state, email) {
    state.hashedEmail = email;
  },
  setCancelUrl(state, url) {
    state.cancelUrl = url;
  },
  setMyPagesUrl(state, url) {
    state.myPageUrl = url;
  },
  previousStep(state) {
    state.currentStepIndex--;
    state.currentStep = state.steps[state.currentStepIndex].type;
    gtm.exitSection();
  },
  nextStep(state) {
    state.currentStepIndex++;
    state.currentStep = state.steps[state.currentStepIndex].type;
    gtm.exitSection();
  },
  nextStepGTM(state) {
    gtm.exitSection();
    gtm.pushNewSection(state.steps[state.currentStepIndex]);
  },
  pushNewSection(state) {
    gtm.pushNewSection(state.steps[state.currentStepIndex]);
  }
};

const getters = {
  baseUrl(state) {
    return state.baseUrl;
  },
  queryParams(state) {
    return state.queryParams;
  },
  currentUrl(state) {
    return state.baseUrl + 'step/' + state.queryParams.step + '/' + state.queryParams.path + '/';
  },
  isOnLastStep(state) {
    return state.steps[state.currentStepIndex + 1].type === 'finished';
  },
  headerText(state, { currentStepData }) {
    return state.headerTextOverride && state.headerTextOverride.length
      ? state.headerTextOverride
      : currentStepData.heading;
  },
  headerImage(state, { currentStepData }) {
    return currentStepData.image;
  },
  headerImageMobile(state, { currentStepData }) {
    return currentStepData.imageMobile;
  },
  currentStepData(state) {
    return state.steps[state.currentStepIndex];
  },
  steps(state) {
    return state.steps;
  },
  email(state) {
    return state.email;
  },
  hashedEmail(state) {
    return state.hashedEmail;
  },
  extension(state, { currentStepData }) {
    return currentStepData.object;
  },
  customer(state) {
    return state.customer;
  },
  activePaymentMethod(state) {
    return state.activePaymentMethod;
  },
  subscription(state) {
    return state.subscription;
  },
  primaryColor(state) {
    return state.primaryColor;
  },
  secondaryColor(state) {
    return state.secondaryColor;
  },
  subscriptionLoaded(state) {
    return state.subscriptionLoaded;
  },
  currentStep(state, { currentStepData }) {
    return currentStepData.type;
  },
  market(state) {
    return state.market;
  },
  cancelUrl(state) {
    return state.cancelUrl;
  },
  myPageUrl(state) {
    return state.myPageUrl;
  },
  isTest(state) {
    return state.isTest;
  },
  testZip(state) {
    return state.testZip;
  },
  oneClickBuy(state) {
    return state.oneClickBuy;
  },
  productType(state) {
    return state.subscription.productType;
  },
  hasDigitalAccess(state) {
    return state.subscription.hasDigitalAccess;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    extensionModule,
    confirmationModule,
    modalModule,
    newsletterModule,
  }
});
