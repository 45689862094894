<template>
  <div class="px-4 md:px-0">
    <h3 class="text-[18px] leading-[22px] md:text-xl md:leading-6 text-center text-balance">{{ byline }}</h3>

    <div class="card card--v2 mt-6 md:mt-9">
      <ul class="tight">
        <li class="flex flex-nowrap mb-4" v-for="(bullet, key) in bullets" :key="key">
          <span v-html="bullet.icon" class="flex items-top mt-2 md:mt-0 md:items-center mr-4 text-bp-blue flex-initial"></span>
          <span v-html="bullet.text" class="flex-auto text-base leading-5 md:text-lg md:leading-[22px]"></span>
        </li>
      </ul>

      <div v-if="showAlert" class="alert alert--v2 alert--information mt-5 md:mt-8 mb-0">
        <div class="alert__icon"><i class="far fa-info-circle"></i></div>
        <div class="alert__content text-sm leading-18 md:text-base md:leading-20">
            {{ currentStepData.accessEnds }}
        </div>
      </div>
    </div>

    <div class="flex flex-nowrap items-start justify-between mt-4 md:mt-7 text-lg">
      <MyButton
        v-on:click="onAbort"
        :id="this.$options.name + '-abort'"
        :styling="'btn btn--link-reversed pb-0 mb-4 md:flex-1 mr-3'"
        :buttonText="t('general', 'cancel')">
      </MyButton>

      <CancelButton
        v-if="isDirectCancel"
        :id="this.$options.name + '-cancel'"
        :styling="'btn btn--cancel-cta mb-4 md:mb-0'"
        :wrapper-styling="'flex-100 md:flex-1 ml-3'"
        :buttonText="t('GeneralV2', 'cancel_subscription_now')">
      </CancelButton>

      <NextStepButton
        v-else
        :id="this.$options.name + '-next'"
        :styling="'btn btn--link pb-0 md:flex-1 ml-3 mb-4 -mb:text-right'"
        :buttonText="t('general', 'continue_cancellation')"
      >
      </NextStepButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import NextStepButton from './NextStepButton';
import MyButton from './Button.vue';
import CancelButton from './CancelButton';
import { translate } from '../store/helpers';

export default {
  name: 'intro-component',
  mounted() {},
  components: { NextStepButton, MyButton,  CancelButton},
  computed: {
    ...mapGetters(['cancelUrl', 'currentStepData', 'productType', 'hasDigitalAccess']),
    showAlert() {
      return true; //this.productType === 'CIR' || this.productType === 'AMB';
    },
    byline() {
      return this.currentStepData.byline || this.t('IntroV2', 'byline');
    },
    shortAbout() {
      return this.t('IntroV2', 'short_about_subscription');
    },
    bullets() {
      return this.currentStepData.bullets || [];
    },
    isDirectCancel() {
      return this.currentStepData.isDirectCancel || false;
    },
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    onAbort() {
      this.pushInteraction({
        event: 'interaction click',
        eventParams: { clickedText: this.t('general', 'cancel')},
        engagement: {
          type: 'click',
          component: {
            name: 'btn btn--link-reversed pb-0 mb-4 md:flex-1 mr-3',
            type: 'btn btn--link-reversed pb-0 mb-4 md:flex-1 mr-3',
          },
        },
      });
      window.location.href = this.cancelUrl;
    },
    ...mapActions({
      pushInteraction: 'pushInteraction',
    }),
  },
};
</script>

<style scoped></style>
