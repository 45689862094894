<template>
    <NewsletterApp :current-step-data="currentStepData"></NewsletterApp>
</template>

<script>
import NewsletterApp from './components/Newsletter';
import {mapActions} from "vuex";

export default {
  name: 'Newsletter',
  components: {
    NewsletterApp,
  },
  props: {
    currentStepData: {
      type: Object,
      default: () => {
        return {
          show: false,
          heading: '',
          text: '',
          expanded_text: '',
          expanded_text_toggle: '',
          policy_text: '',
          policy_link_text: '',
          sourcecode: '',
          privacyPolicyUrl: '',
          dataLayer: [],
        };
      }
    },
  },
  data() {
    return {};
  },
  beforeMount() {
    this.fetchEmail();
  },
  methods: {
    ...mapActions([
      'fetchEmail',
    ])
  },
  computed: {
  }

};
</script>

<style lang="scss">
</style>
