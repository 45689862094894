class DataLayer {
  constructor() {
    this.dataLayer = window.dataLayer || [];
    this.pushed = [];
  }

  push(data) {
    if (window.location.href.includes('.local') || window.location.href.includes('.iveo') || window.location.href.includes('-service.dk')) {
      console.info('GTM PUSH:', data);
    }

    this.pushed.push(data);
    (window.dataLayer || []).push(data);
  }

  sendInteraction(event, eventParams, type, component) {
    const theEvent = {
      event,
      eventParams,
      engagement: {
        type,
        component,
      },
    };

    this.push(theEvent);
    this.push({
      engagement: undefined,
      eventParams: undefined,
    })
  }
}

let gtm = new DataLayer();

export default gtm;
