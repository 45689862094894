<template>
  <div class="mt-10 mb-2 md:mt-0">
      <h3 class="text-[18px] leading-[22px] md:text-xl md:leading-6 text-center">{{ this.t('ExtensionV2ab', 'checkout_header') }}</h3>

      <Checkout
        :offer="selectedOffer"
        :campaign="extension"
        :activePaymentMethod="activePaymentMethod">
      </Checkout>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Checkout from '../Checkout';
import { translate } from '../../store/helpers';

export default {
  components: { Checkout },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters([
      'primaryColor',
      'secondaryColor',
      'activePaymentMethod',
    ]),
    ...mapState('extensionModule', [
      'selectedOffer',
      'extension',
    ]),
  },
};
</script>

<style scoped>

</style>
