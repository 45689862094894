export function getQueryParameter() {
  const a = window.location.search.substr(1).split('&');
  if (a === '') return {};
  const b = {};
  for (let i = 0; i < a.length; ++i) {
    const p = a[i].split('=', 2);
    if (p.length === 1) b[p[0]] = '';
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
  }
  return b;
}

export function imageUrl(image) {
  return `${window.location.protocol}//${window.location.host}/content/themes/bonnier-customer-service/img/${image}`;
}

export function translate(component, key) {
  const string = window.application?.lang[component][key];
  if (string === undefined) {
    return '';
  }
  return string;
}

export function scrollToTop() {
  const frame = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(frame);
      window.scrollTo(0, c - c / 8);
    }
  };
  frame();
}
