import $ from 'jquery';

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  modifyDOM() {
    $('.sidebar-menu .active, .sidebar-menu .current-menu-item, .header-menu .active, .header-menu .current-menu-item').each(function() {
      let $parent = $(this).closest('.menu-item-has-children').first();
      if($parent.length) {
        $parent.find('> .sub-menu').toggle();
        $parent.toggleClass('toggled');
        $parent = $parent.parent().closest('.menu-item-has-children').first();
        if($parent.length) {
          $parent.find('> .sub-menu').toggle();
          $parent.toggleClass('toggled');
        }
      }
    });
  },
  addEvents() {
    $(document).on('click', '.menu-item-has-children', this.eventHandlers.toggleSubMenu)
  },

  eventHandlers: {
    toggleSubMenu(e) {
      console.log(e);
      e.stopImmediatePropagation();
      if(e.target.nodeName !== 'A') {
        $(this).find('> .sub-menu').toggle();
        $(this).toggleClass('toggled');
      }
    }
  }
}
