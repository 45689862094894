<template>
  <div>
    <transition name="slide-fade"  mode="out-in">
      <component :is="currentComponent"></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Intro from './Intro';
import Extend from './Extend';
import Questionnaire from './Questionnaire';
import Confirmation from './Confirmation';
import Finished from './Finished';

export default {
  components: {
    Intro,
    Extend,
    Questionnaire,
    Confirmation,
    Finished,
  },
  computed: {
    ...mapGetters(['currentStep']),
    currentComponent() {
      switch (this.currentStep) {
        case 'intro':
          return Intro;
        case 'extension':
          return Extend;
          case 'questionnaire':
          return Questionnaire;
        case 'confirmation':
          return Confirmation;
        case 'finished':
          return Finished;
        default:
          return Intro;
      }
    },
  },
};
</script>

<style scoped></style>
