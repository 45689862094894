<template>
  <div class="mx-auto">
    <div v-for="(offer) in offers" class="card card--offer flex mb-6" v-bind:key="offer.id">
      <div class="card__header">
        {{ currentStepData.offer_header }}
      </div>
      <div class="card__body" v-if="hasSelectedOffer">
        <div class="block text-[28px] leading-[32px] md:text-[32px] md:leading-[36px] text-center header-font font-black mb-2 md:mb4 text-balance">
          {{ selectedOffer.rebateLabelV2AB }}
        </div>

        <div class="block text-center mb-5 md:mb-6">
         <span class="inline-block leading-5 md:text-lg md:leading-[22px] text-balance">{{ selectedOffer.rebateLabelBylineAB }}</span>
        </div>

        <div class="block text-center mb-3 md:mb-5">
          <OneClickExtension
            v-if="oneClickBuy"
            :styling="'btn btn--marketing-cta text-balance'"
            :button-text="extendTitle">
          </OneClickExtension>

          <NextExtension
            v-else
            :styling="'btn btn--marketing-cta text-balance'"
            :button-text="extendTitle">
          </NextExtension>
        </div>

        <div class="block text-center">
          <span v-if="oneClickBuy" class="inline-block text-xs leading-4 md:text-sm md:leading-18 text-bp-darkestgray font-normal">{{ selectedOffer.rebateLabelFooterOneClick }}</span>
          <span v-else class="inline-block text-xs leading-4 md:text-sm md:leading-18 text-bp-darkestgray font-normal">{{ selectedOffer.rebateLabelFooterAB }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { translate } from '../store/helpers';
import {scrollToTop} from "@/apps/saveflow/store/helpers";
import NextExtension from './extension-steps/NextExtension';
import OneClickExtension from './extension-steps/OneClickBuyExtension';

export default {
  name: 'offers-component',
  components: {
    NextExtension,
    OneClickExtension,
  },
  data() {
    return {
      selected: {}
    };
  },
  watch: {
    selected(newValue) {
      if (newValue === null) {
        return;
      }

      this.$store.dispatch(
        `${this.storeModule}/setSelectedOffer`,
        this.offers.filter(offer => parseInt(offer.id, 10) === parseInt(newValue,10))[0]
      );
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.selected = this?.offers[0]?.id ?? null;
    });
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    storeModule: {
      type: String,
      default: ''
    },
    compareToCurrentSubscription: {
      type: Boolean,
      default: false
    },
    cover: {
      type: String,
      default: ''
    },
    shouldHideFirstBullet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['primaryColor', 'secondaryColor' , 'currentStepData', 'oneClickBuy']),
    selectedOffer() {
      return this.$store.state[this.storeModule].selectedOffer;
    },
    selectedCampaign() {
      return this.$store.state[this.storeModule].selectedCampaign;
    },
    hasSelectedOffer() {
      return this.selectedOffer !== null;
    },
    extendTitle() {
      if (this.oneClickBuy) {
        return this.t('ExtensionV2ab', 'yes_extend_one_click')
      }

      return this.t('ExtensionV2ab', 'yes_please_extend')
    }
  },
  methods: {
    ...mapActions({
      next: 'extensionModule/next',
    }),
    goNext() {
      scrollToTop();
      this.updateUrl(this.getIndex + 1);

      setTimeout(() => {
        this.next();
      }, 400);
    },
    showOfferContent(offer) {
      return offer.id !== -1;
    },
    t(component, key) {
      return translate(component, key);
    },
  },
};
</script>

<style scoped type="text/scss">
</style>
