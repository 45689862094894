import $ from 'jquery';

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  modifyDOM() {
  },
  addEvents() {
    window.addEventListener('increment-view-count', this.eventHandlers.incrementViewCount);
  },

  eventHandlers: {
    incrementViewCount(e) {
      if(e.detail.open) {
        $.post(window.ajax_url, {
          action: 'increment_view_count',
          id: e.detail.id
        })
      }
    }
  }
}
