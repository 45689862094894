import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  modals: {},
};

const getters = {
  modals(state) {
    return state.modals;
  },
};
const actions = {
  open({commit}, modal) {
    commit('open', modal);
  },
};
const mutations = {
  close(state, modal) {
    Vue.set(state.modals, modal, false);
  },
  open(state, modal) {
    Vue.set(state.modals, modal, true);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
