<template>
  <div class="alert" :class="typeClass">
    <div class="alert__icon"><i :class="typeIcon"></i></div>
    <div class="alert__content">
      {{ alertContent }}
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  props: {
    alertType: {
      type: String,
      default: '',
    },
    alertContent: {
      type: String,
      default: '',
    },
  },
  computed: {
    typeIcon() {
        switch(this.alertType) {
          case 'success':
            return 'fas fa-exclamation-circle fa-2x text-bp-s';
          case 'error':
            return 'fas fa-exclamation-circle fa-2x text-bp-error';
        }
        return 'fas fa-exclamation-circle fa-2x text-bp-s';
    },
    typeClass() {
      switch(this.alertType) {
        case 'success':
          return 'alert--success';
        case 'error':
          return 'alert--error';
      }
      return 'alert--success';
    }
  },
  watch: {
  },
  methods: {
  }
};
</script>

<style scoped>
</style>
