import $ from 'jquery';
import debounce from "../utils/debounce";

export default {
  self: this,

  init() {
    this.addEvents();
    this.setVars();
    this.onLoad();
  },

  setVars() {
    this.premiums = $('.premium-results .premium-item');
    this.premiumSelection = this.premiums.toArray();
    this.page = 1;
    this.pageSize = 20;
    this.pages = (this.premiums.length / this.pageSize);
  },

  addEvents() {

    window.addEventListener('clear-premium-search', this.eventHandlers.onClearPremiumSearch.bind(this));
    window.addEventListener('premium-search-page', this.eventHandlers.onShowMore.bind(this));
    window.addEventListener('premium-search', debounce(this.eventHandlers.onPremiumSearch.bind(this), 1000));
  },

  onLoad() {
    this.eventHandlers.onPaginate.call(this);

    if (this.pages > this.page) {
      this.eventHandlers.onShowLoad();
    }
  },

  eventHandlers: {
    onShowMore(e) {
      this.page = e.detail.page;
      this.eventHandlers.onPaginate.call(this);
    },
    onPaginate() {
      this.premiumSelection.forEach( (el, idx) => {
          if ( (idx + 1) <= (this.page * this.pageSize) ) {
            $(el).show();
          }
      });

      if (this.page >= this.pages) {
        this.eventHandlers.onHideLoad();
      } else {
        this.eventHandlers.onShowLoad();
      }
    },
    onShowLoad() {
      $('#premium-resulsts-load-more').show();
    },
    onHideLoad() {
      $('#premium-resulsts-load-more').hide();
    },
    onClearPremiumSearch() {
      $('.premium-search .fa-search').removeClass('invisible');
      $('.premium-search .fa-spin').addClass('invisible');
      $('.premium-search .fa-times').addClass('invisible');

      this.premiums.hide();
      this.premiumSelection = this.premiums.toArray();
      this.page = 1;
      this.pages = (this.premiumSelection.length / this.pageSize);

      this.eventHandlers.onPaginate.call(this);
    },
    onPremiumSearch(e) {
      if(e.detail.search) {
        this.eventHandlers.onHideLoad();
        $('.premium-search .fa-search').addClass('invisible');
        $('.premium-search .fa-spin').removeClass('invisible');
        $('.premium-search .fa-times').addClass('invisible');
        $.get(window.ajax_url + '?action=search_premiums&s=' + e.detail.search).then(res => {
          let hits = 0;
          this.premiums.hide();
          if(res && res.has_results) {
            this.premiumSelection = [];
            res.result.forEach(id => {
               let $el = $(`[data-id=${id}]`);
               if ( $el.show().length && hits < this.pageSize ) {
                 hits++;
                 this.premiumSelection.push( $el.get() )
               }
            })
            this.pages = (hits / this.pageSize);
            this.eventHandlers.onPaginate.call(this);
          }

         let event = new CustomEvent('premium-search-result', {
            detail: {
              search: e.detail.search,
              hits,
            }
          });
          window.dispatchEvent(event);

        }).always(() => {
          $('.premium-search .fa-times').removeClass('invisible');
          $('.premium-search .fa-spin').addClass('invisible');
        });
      } else {
        this.premiums.hide();
      }
    }
  }
}
