import Axios from 'axios';
import { gtm } from '../../utils/gtm';
import {sha256} from "../../../../utils/crypto";

const state = {
  newsletterLoading: false,
  newsletterSuccess: false,
  newsletterError: false
};

const getters = {};

const actions = {
  async addNewsletter({ commit }, { email, sourceCode }) {
    commit('newsletterLoading', true);
    const data = new FormData();
    data.append('email', email);
    data.append('sourceCode', sourceCode);
    data.append('action', 'add_newsletter_permission');
    const hashedEmail =  await sha256(email);


    gtm.push({
      event: 'newsletterSubscribeSubmit',
      userId: hashedEmail,
      newsletterPermissionType: sourceCode
    });
    const response = await Axios.post('/wp/wp-admin/admin-ajax.php', data);
    if (response.data.success) {
      gtm.push({
        event: 'newsletterSubscribeSuccess',
        userId: hashedEmail,
        newsletterPermissionType: sourceCode
      });
      gtm.pushNewSection({
        dataLayer: {
          pageName: '5.2 Newsletter|confirmation'
        }
      });
      commit('newsletterSuccess', response);
    } else {
      commit('newsletterError');
      gtm.push({
        event: 'newsletterSubscribeError',
        userId: hashedEmail,
        newsletterPermissionType: sourceCode
      });
    }
    commit('newsletterLoading', false);
  }
};

const mutations = {
  newsletterLoading(state, loading) {
    state.newsletterLoading = loading;
  },
  newsletterError(state) {
    state.newsletterSuccess = false;
    state.newsletterError = true;
  },
  newsletterSuccess(state, response) {
    state.newsletterSuccess = response.data.success;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
