import $ from 'jquery';

export default {
  init() {
    this.addEvents();
    this.setVars();
  },

  setVars() {
    this.faqs = $('.faq-results .faq-item');
    this.noResults = $('.faq-results-no-results');
  },

  addEvents() {
    window.addEventListener('faq-search', this.eventHandlers.onFaqSearch.bind(this));
  },

  eventHandlers: {
    onFaqSearch(e) {
      this.noResults.hide();
      if(e.detail.search) {
        $('.faq-search .fa-search').hide();
        $('.faq-search .fa-spin').show();
        $.get(window.ajax_url + '?action=search_faqs&s=' + e.detail.search).then(res => {
          this.faqs.hide();
          if(res && res.has_results) {
            res.result.forEach(id => {
              $(`[data-id=${id}]`).show();
            })
          } else {
            this.noResults.show();
          }
        }).always(() => {
          $('.faq-search .fa-search').show();
          $('.faq-search .fa-spin').hide();
        });
      } else {
        this.faqs.hide();
      }
    }
  }
}
