import '@fortawesome/fontawesome-pro/css/all.css';


window.baseUrl = `${window.location.protocol}//${window.location.host}`;
window.axios = require('axios');

window.axios.defaults.baseURL = window.baseUrl;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import $ from 'jquery';
import Router from './utils/router';
import common from './routes/common';
import saveflowFallback from './routes/saveflow-fallback';
import faqOverview from './routes/faq-overview';
import premiumOverview from './routes/premium-overview';
import profile from './routes/profile';
import subscription from './routes/subscription';

const routes = {
  common,
  page_template_template_faq: faqOverview,
  page_template_template_premiums: premiumOverview,
  page_template_template_fallback_saveflow: saveflowFallback,
  virtual_page_profile: profile,
  virtual_page_subscription: subscription,
  virtual_page_agreement: subscription,
  virtual_page_bookclub: subscription,
  virtual_page_subscriptions: subscription,
  virtual_page_subscription_benefits: subscription,
};

$(document).ready(() => new Router(routes).loadEvents());


