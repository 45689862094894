import Swiper from 'swiper';
import $ from 'jquery';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    $('.swiper-container').each(function() {
      const $el = $(this);
      new Swiper ($el[0], {
        slidesPerView: 'auto',
        spaceBetween: 32,
        autoHeight: $(window).width() < 768,

        // Navigation arrows
        navigation: {
          nextEl: $el.siblings('.swiper-navigation').find('.swiper-next').first()[0],
          prevEl: $el.siblings('.swiper-navigation').find('.swiper-prev').first()[0],
        },
      })
    })

  },

}
