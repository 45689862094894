<template>
  <div :class="wrapperStyling">
    <Button :buttonText="buttonText" :styling="styling" v-on:click="onCancel" :loading="loading"
            :disabled="subscriptionCancelledError"></Button>

    <p v-if="subscriptionCancelledError" class="text-red-700 mt-4">
      {{ t('ConfirmationV2', 'error') }}
    </p>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Button from './Button.vue';
import {scrollToTop, translate} from '../store/helpers';

export default {
  components: {
    Button,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    styling: {
      type: String,
      default: 'btn btn--primary-cta',
    },
    wrapperStyling: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      loading: state => state.confirmationModule.subscriptionCancelLoading,
      subscriptionCancelledError: state => state.confirmationModule.subscriptionCancelledError,
      subscriptionCancelledSuccess: state => state.confirmationModule.subscriptionCancelledSuccess,
      isTest: state => state.isTest,
    }),
  },
  mounted() {
    this.fetchIsTest();
    this.fetchSubNr();
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
    ...mapActions({
      cancelSubscription: 'confirmationModule/cancelSubscription',
      fetchIsTest: 'confirmationModule/fetchIsTest',
      fetchSubNr: 'confirmationModule/fetchSubNr'
    }),
    ...mapMutations([
      'nextStep',
    ]),
    async onCancel() {
      await this.cancelSubscription(false);

      if (this.subscriptionCancelledSuccess) {
        this.next();
      }
    },
    next() {
      scrollToTop();
      setTimeout(() => {
        this.nextStep();
      }, 400);
    },
  },
};
</script>

<style scoped>

</style>
