import $ from 'jquery';
import Cookies from 'js-cookie';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    window.addEventListener('close-notice', this.eventHandlers.onCloseNoticeClick);
  },

  eventHandlers: {
    onCloseNoticeClick(e) {
      const id = e.detail.id;
      Cookies.set('notice_closed_' + id, true);
      $('.sticky-notice').remove();
    }
  }
}
