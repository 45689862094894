import $ from 'jquery';

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  modifyDOM() {
    let commentFound = false;
    $('.content > *').each(function() {
      if(commentFound) {
        $(this).addClass('hidden');
      }
      var comment = $(this).contents().filter(function() {
        return this.nodeType === 8;
      }).get(0);
      if(comment) {
        $(this).addClass('read-more');
        $(this).html('<span class="text-gray-text font-semibold">Läs mer</span> <i class="text-gray-text fal fa-chevron-down ml-1"></i>');
        commentFound = true;
      }
    });
  },
  addEvents() {
    $(document).on('click', '.read-more', this.eventHandlers.onReadMoreClick)
  },

  eventHandlers: {
    onReadMoreClick() {
      $(this).closest('.content').find('.hidden:not(span)').removeClass('hidden');
      $(this).hide();
    }
  }
}
