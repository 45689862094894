import Axios from 'axios';
import $ from 'jquery';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '#fallback-cancel', this.onCancelClick.bind(this));
  },

  onCancelClick({currentTarget}) {
    Axios.post('/wp/wp-admin/admin-ajax.php?action=cancel_subscription').then(res => {
      if (res.data.success) {
        $(currentTarget).remove();
        $('.hidden').show();
      } else {
        window.location.href = $(currentTarget).data('error-page');
      }
    });

  }
}
