import $ from 'jquery';
import dataLayer from '../utils/data-layer';

export default {
  init() {
    this.addEvents();
  },
  addEvents() {
    $(document).on('submit', '.feedback-form', this.eventHandlers.onFeedbackFormSubmit);
    $(document).on('click', '.feedback-btn-yes', this.eventHandlers.onPositiveFeedback);
    $(document).on('click', '.feedback-btn-no', this.eventHandlers.onNegativeFeedback);
  },

  eventHandlers: {
    onFeedbackFormSubmit(e) {
      e.preventDefault();
      const data = $(this).serializeArray();
      let postData = {};
      data.forEach(d => {
        postData[d.name] = d.value
      });
      $.post(window.ajax_url, postData);
      $('.feedback-info').hide();
      $('.feedback-success').show();
    },
    onPositiveFeedback() {
      $('.feedback-info').hide();
      $('.feedback-success').show();
      dataLayer.push({
        customerFeedback: 'satisfied'
      });
    },
    onNegativeFeedback() {
      $('.feedback-success').hide();
      dataLayer.push({
        customerFeedback: 'dissatisfied'
      })
    }
  }
}
