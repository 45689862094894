<template>
  <div class="max-w-[600px] mx-auto">
    <h3 class="text-[18px] leading-[22px] md:text-xl md:leading-6 text-center text-balance" v-html="byline"></h3>

    <div class="mt-6 md:mt-9">
      <Offers
        :store-module="'extensionModule'"
        :cover="extension.cover"
        :should-hide-first-bullet="parseInt(extension.bmd_campaign_id, 10) < 366167"
        :offers="offers">
      </Offers>

      <div class="flex flex-nowrap items-start justify-between mt-4 md:mt-7 text-lg">
          <PreviousStepButton
            :id="this.$options.name + '-prev'"
            :styling="'btn btn--link-reversed pb-0 mb-4 md:flex-1 mr-3'"
            :button-text="t('GeneralV2', 'go_back')">
          </PreviousStepButton>

          <CancelButton
            v-if="isOnLastStep"
            :id="this.$options.name + '-cancel'"
            :styling="'btn btn--cancel-cta pb-0 md:flex-1 ml-3 mb-4'"
            :wrapper-styling="'flex-100 md:flex-1'"
            :buttonText="t('GeneralV2', 'cancel_subscription_now')">
          </CancelButton>

          <NextStepButton
            v-else
            :id="this.$options.name + '-next'"
            :styling="'btn btn--link pb-0 md:flex-1 ml-3 mb-4 -mb:text-right'"
            :buttonText="t('general', 'continue_cancellation')"
          >
          </NextStepButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PreviousStepButton from "../PreviousStepButton";
import NextStepButton from "../NextStepButton";
import CancelButton from "../CancelButton";
import Offers from '../Offers';
import { translate } from '../../store/helpers';

export default {
  name: 'extend-offers',
  components: {
    PreviousStepButton,
    NextStepButton,
    CancelButton,
    Offers,
  },
  methods: {
    t(component, key) {
      return translate(component, key);
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters(['isOnLastStep', 'currentStepData']),
    ...mapState('extensionModule', ['extension', 'selectedOffer']),
    byline() {
      return this.currentStepData.byline;
    },
    hasSelectedOffer() {
      return this.selectedOffer !== null;
    },
    offers() {
      if (!this.extension?.offers) {
        return [];
      }

      return this.extension.offers;
    },
  }
};
</script>

<style scoped></style>
