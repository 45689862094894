import Axios from 'axios';

const state = {
  subscriptionCancelLoading: false,
  subscriptionCancelled: false,
  subscriptionCancelledError: false,
  subscriptionCancelledSuccess: false,
  isTest: false,
  subNr: false,
};

const getters = {
};

const actions = {
  async cancelSubscription({ state, commit }, redirect = true) {
    commit('subscriptionCancelLoading', true);
    const data = new FormData();
    data.append('is_test', state.isTest);
    data.append('subnr', state.subNr);
    const response = await Axios.post('/wp/wp-admin/admin-ajax.php?action=cancel_subscription', data);

    if(response.data.success) {
      commit('subscriptionCancelled', response);
      commit('nextStepGTM', null, {root: true});

      if (redirect) {
        window.location.href = response.data.url;
      }
      commit('subscriptionCancelledSuccess', true);

    } else {
      commit('subscriptionCancelledError');
    }
    commit('subscriptionCancelLoading', false);
  },
  fetchIsTest({ commit }) {
    commit('setIsTest', window.application.isTest === '1');
  },
  fetchSubNr({ commit }) {
    commit('setSubNr', window.application.subscription.subscriptionNumber ?? window.application.subscription.agreementId);
  }
};

const mutations = {
  setSubNr(state, subscriptionNumber) {
    state.subNr = subscriptionNumber;
  },
  setIsTest(state, isTest) {
    state.isTest = isTest;
  },
  subscriptionCancelLoading(state, loading) {
    state.subscriptionCancelLoading = loading;
  },
  subscriptionCancelledError(state) {
    state.subscriptionCancelled = false;
    state.subscriptionCancelledError = true;
  },
  subscriptionCancelled(state, response) {
    state.subscriptionCancelled = response.data.success;
  },
  subscriptionCancelledSuccess(state, response) {
    state.subscriptionCancelledSuccess = response;
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
