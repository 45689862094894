class Gtm {
  constructor() {
    this.dataLayer = window.dataLayer || [];
    this.pushed = [];
    this.currentStep = 1;
    this.addedProduct = null;
    this.offerSelected = null;
    window.getPushedData = () => {
      console.log(this.pushed);
    };
  }

  get initialData() {
    if (
      window.application &&
      window.application.dataLayer &&
      window.application.dataLayer.initialData
    ) {
      return window.application.dataLayer.initialData;
    }
    return {};
  }

  initialPush() {
    this.push(this.initialData);
  }

  currencyCode() {
    return window.application.currency;
  }

  market() {
    return window.application.market;
  }

  push(data) {
    if (window.location.href.includes('.local')) {
      console.info('GTM PUSH:', data);
    }
    this.pushed.push(data);
    (window.dataLayer || []).push(data);
  }

  exitSection() {
    this.push({
      event: 'exitSection'
    });
    if (this.addedProduct) {
      this.removeFromCart(this.addedProduct);
    }
  }

  addToCart(product) {
    this.addedProduct = product;
    this.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: this.currencyCode(),
        add: {
          products: [product]
        }
      }
    });
  }

  removeFromCart(product) {
    this.push({
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: this.currencyCode(),
        remove: {
          products: [product]
        }
      }
    });
    this.addedProduct = null;
  }

  removeAddedProductFromCart() {
    if (this.addedProduct) {
      this.removeFromCart(this.addedProduct);
    }
  }

  initCheckout(product) {
    if (this.addedProduct) {
      this.removeFromCart(this.addedProduct);
    }
    this.currentStep = 1;
    this.addToCart(product);
    this.trackCheckout(product, {
      step: 1,
      option: 'N/A'
    });
  }

  impressions(campaigns) {
    this.push({
      ecommerce: {
        currencyCode: this.currencyCode(),
        impressions: campaigns.map((c, idx) => {
          const offer = c.offers[0].dataLayer;
          return {
            position: idx + 1,
            ...offer
          };
        })
      }
    });
  }

  productClick(campaign, idx) {
    const dataLayerProduct = campaign.offers[0].dataLayer;
    this.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: {list: 'cross-sells'},
          products: [{...dataLayerProduct, position: idx}]
        }
      }
    });
  }

  selectOffer(product) {
    let actionField = {
      step: 2,
      option: product.dimension91
    };
    if (this.currentStep < 3) {
      this.offerSelected === null ? this.trackCheckout(product, actionField) : this.trackCheckoutOption(actionField);

      actionField = {
        step: 3,
        option: 'Customer details provided'
      }
      this.offerSelected === null ? this.trackCheckout(product, actionField) : this.trackCheckoutOption(actionField);

    } else {
      if (!(this.addedProduct && this.addedProduct.id === product.id)) {
        this.offerSelected === null ? this.trackCheckout(product, actionField) : this.trackCheckoutOption(actionField);
      }
    }
    this.addedProduct = product;
    this.offerSelected = true;
    this.currentStep = 3;
  }

  selectPaymentOption(selectedPaymentOption, initialOption = false) {
    const actionField = {
      step: 4,
      option: selectedPaymentOption
    };
    if (initialOption) {
      this.trackCheckout(this.addedProduct, actionField);
    } else {
      this.trackCheckoutOption(actionField);
    }
    this.currentStep = 4;
  }

  clickPurchaseButton() {
    const actionField = {
      step: 5,
      option: 'Purchase button clicked'
    };

    this.trackCheckout(this.addedProduct, actionField);
    this.currentStep = 5;
  }

  trackCheckout(product, actionField) {
    this.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: this.currencyCode(),
        checkout: {
          actionField,
          products: [product]
        }
      }
    });
  }

  trackCheckoutOption(actionField) {
    this.push({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField
        }
      }
    });
  }

  pushNewSection(section) {
    const {
      id,
      flow,
      segment,
      current_subscription
    } = window.application.saveFlow.dataLayer;

    this.push({
      pageName: section.dataLayer.pageName,
      flow,
      segment,
      current_subscription,
      event: 'VirtualPageView'
    });

    this.push( this.newPageViewObject(parseInt(id + '0000' + section.dataLayer.sectionId, 10), section.dataLayer.name) );
  }

  pushInteraction(eventData) {
    this.push(eventData);
  }

  pushViewItem(offers) {
    console.log('pushViewItem');
    this.pushNewDatalayerEvent(this.newDataLayerObject('view_item', offers[0].brand, offers));
  }

  pushBeginCheckout(offer) {
    console.log('pushBeginCheckout');
    this.pushNewDatalayerEvent(this.newDataLayerObject('begin_checkout', offer.brand, offer));
  }

  pushAddPaymentInfo(offers) {
    console.log('pushAddPaymentInfo');
    this.pushNewDatalayerEvent(this.newDataLayerObject('add_payment_info', offers[0].brand, offers));
  }

  pushGoToPayment(offers) {
    console.log('go_to_payment');
    this.pushNewDatalayerEvent(this.newDataLayerObject('go_to_payment', offers[0].brand, offers));
  }

  pushNewDatalayerEvent(eventData) {
    this.push(eventData);
    this.push({bn_ecommerce: undefined});
  }

  newDataLayerObject(event, brand, products) {
    const market = products[0]?.market ?? this.market();
    return {
      event,
      flow: {
        name: "saveflow",
      },
      bn_ecommerce: {
        currency: this.currencyCode().toLowerCase(),
        campaign:
          {
            affiliation: "ksw",
            name: brand,
            market,
          },
        products
      }
    };
  }

  newPageViewObject(id, title) {
    const {
      segment,
      current_subscription,
      market,
      isProduction,
      lastModifiedTime,
      publishedTime,
    } = window.application.saveFlow.dataLayer;
    console.log(window.history.state);
    const referrer = window.history.state?.referrer ?? document.referrer;

    return {
      event: 'page view',
      eventParams: {
        segment,
        current_subscription,
      },
      trigger: 'virtual pageview',
      browser: {
        viewport: {
          height: window.innerHeight,
          width: window.innerWidth
        }
      },
      content: {
        articleIndex: 1,
        contentAccessStatus: 'free',
        id: {
          system: 'kundeserviceweb',
          value: id
        },
        isStandalone: true,
        lastModifiedTime,
        location: window.location.href,
        publishedTime,
        title,
        type: 'saveflow',
        userHasAccess: true
      },
      device: {
        screen: {
          height: window.screen.height,
          width: window.screen.width
        }
      },
      environment: {
        isProduction,
        platform: {
          name: 'kundeserviceweb'
        }
      },
      org: {
        market,
        service: 'customer support',
        brandCode: 'KSW',
      },
      referrer,
      user: {
        id: {
          system: 'bp_hash',
          value: window.application.saveFlow.hashedEmail
        },
        isLoggedIn: true
      }
    };
  }

  pushNewPageViewEvent(eventData) {
    this.push(eventData);
    this.push({eventParams: undefined});
  }
}

export const gtm = new Gtm();
